<template>
  <ModalFormGeneric :id="COMPNAME" :title="$t('sgnup.tc')" :btnText="$t('sgnup.b')" :btnDisabled="!val" btnIcon="lrg"
      @submit="onSubmit"
      @reset="onReset"
      :ref="COMPNAME">
    <b-form-group :label="$t('lgin.f.emaill')" :label-for="FORMNAME + 'Email'" class="text-left">
      <b-form-input type="email" :id="FORMNAME + 'Email'" v-model="f.emailAddress" :state="valEmail" required />
      <b-form-invalid-feedback :state="valEmail">
        {{ $t('lgin.f.emailf') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="$t('lgin.f.pwl')" :label-for="FORMNAME + 'Pw'" class="text-left">
      <b-form-input type="password" :id="FORMNAME + 'Pw'" v-model="f.password" :state="valPw" class="mt-2" pattern=".{8,}" required />
      <b-form-invalid-feedback :state="valPw">
        {{ $t('lgin.f.pwf') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="$t('sgnup.f.lanl')" :label-for="FORMNAME + 'Lan'" class="text-left">
      <LangFormDropdown :id="FORMNAME + 'Lang'" v-model="f.languageId" :autoload="true" />
      <b-form-invalid-feedback :state="valLang">
        {{ $t('sgnup.f.lanf') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-row class="mx-3">
      <b-form-checkbox class="mt-2 text-left" :id="FORMNAME + 'Tos'" v-model="f.acceptedTos" :state="valTos" required>
        <i18n path="sgnup.f.tos2" tag="span" class="text-dark">
          <template #tos>
            <b-link to="toscust" target="_blank" :class="`text-`+(valTos == null ? `primary` : (valTos ? `success` : `danger`))">
              <span style="text-decoration:underline!important;">{{ $t('sgnup.f.tosl') }}</span>
            </b-link>
          </template>
          <template #price>
            <b-link to="toscust#payments" target="_blank" :class="`text-`+(valTos == null ? `primary` : (valTos ? `success` : `danger`))">
              <span style="text-decoration:underline!important;">{{ $t('sgnup.f.tosp') }}</span>
            </b-link>
          </template>
        </i18n>
      </b-form-checkbox>
    </b-form-row>
    <b-form-row v-if="false" class="mx-3">
      <b-form-checkbox class="mt-2 text-left" :id="FORMNAME + 'Cap'" v-model="f.acceptedCap" :state="valCap" required>
        <i18n path="sgnup.f.cap" tag="span" class="text-dark">
          <template #cookie>
            <b-link to="contentpolicy#cookies" target="_blank" :class="`text-`+(valCap == null ? `primary` : (valCap ? `success` : `danger`))">
              <span style="text-decoration:underline!important;">{{ $t('sgnup.f.capc') }}</span>
            </b-link>
          </template>
          <template #privacy>
            <b-link to="contentpolicy#privacy" target="_blank" :class="`text-`+(valCap == null ? `primary` : (valCap ? `success` : `danger`))">
              <span style="text-decoration:underline!important;">{{ $t('sgnup.f.capp') }}</span>
            </b-link>
          </template>
        </i18n>
      </b-form-checkbox>
    </b-form-row>
    <!--<b-form-row class="justify-content-center align-items-start mt-3">
      <b-img class="rounded" src="@/assets/recaptcha.png" alt="" width="250" />
    </b-form-row>-->
  </ModalFormGeneric>
</template>

<script>
// import { BIconAt, BIconLock } from 'bootstrap-vue';
import ModalFormGeneric from '@/components/ModalFormGeneric';
import LangFormDropdown from '@/components/shared/LangFormDropdown';
import { REST } from '@/components/RestCall';

export default {
  data() {
    return {
      COMPNAME: 'sgnWindowC',
      FORMNAME: 'sgnInputC',
      f: {
        emailAddress: '',
        password: '',
        languageId: null,
        isNewLocation: true,
        acceptedTos: false,
        acceptedCap: true,
      },
    }
  },
  created() {
    this.f.languageId = this.$store.state.settings.languageId;
  },
  watch: {
    settLangId: function(val) {
      if (this.f.languageId != val) this.f.languageId = val;
    },
  },
  computed: {
    optTypes() {
      return [
        {text: this.$t('sgnup.f.locn'), value: true},
        {text: this.$t('sgnup.f.loce'), value: false},
      ];
    },
    valLoc() {
      if (this.f.emailAddress === '' || this.f.password === '') return null;
      return this.f.isNewLocation != null;
    },
    valTos() {
      if (this.f.emailAddress === '' || this.f.password === '') return null;
      return this.f.acceptedTos == true;
    },
    valCap() {
      if (this.f.emailAddress === '' || this.f.password === '') return null;
      return this.f.acceptedCap == true;
    },
    valEmail() {
      if (this.f.emailAddress === '') return null;
      let rExp = /(.+)@(.+){2,}\.(.+){2,}/;
      return rExp.test(this.f.emailAddress.toLowerCase());
    },
    valPw() {
      if (this.f.password === '') return null;
      return this.f.password.length >= 8 ? true : false;
    },
    val() {
      return (this.f.isNewLocation !== null && this.valTos === true && this.valCap === true &&
              this.valEmail === true && this.valPw === true && this.valLang === true);
    },
    settLangId() {
      return this.$store.state.settings.languageId;
    },
    valLang() {
      return typeof this.f.languageId == 'number';
    },
  },
  methods: {
    onSubmit() {
      if (this.val) {
        REST.post('/customers', this.f)
          .then(resp => {
            //const uuid = resp.h['x-uuid'].split('.')[0];
            // show for user
            this.$store.commit('showSuccess',resp.d.message);
          }).catch(e => {
            this.$store.commit('showWarn',e.message)
          });
        this.$refs[this.COMPNAME].hide();
        this.$emit('submit');
      }
    },
    onReset() {
      this.f.emailAddress = '';
      this.f.password = '';
      this.f.isNewLocation = true;
      this.f.acceptedTos = false;
      this.f.acceptedCap = true;
      this.$emit('reset');
    },
  },
  components: {
    ModalFormGeneric,
    LangFormDropdown,
    // BIconAt,
    // BIconLock,
  },
}
</script>